<template>
	<div class="news-detail">
		<w-navTab titleText="详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="head-nav">
				<div class="head-title">{{ newsDetail.title }}</div>
				<div class="head-bottom">
					<div class="head-bottom-item">
						<div class="text title">作者: {{ newsDetail.source }}</div>
						<div class="text">时间: {{ newsDetail.create_time }}</div>
					</div>
					<div class="head-bottom-item">
						<div class="text">点赞: {{ newsDetail.approval }}</div>
						<div class="text">阅读: {{ newsDetail.views }}</div>
					</div>
				</div>
			</div>

			<div class="content">
				<div class="content-top" v-html="newsDetail.content"></div>
			</div>

			<div class="footer">
				<!-- 点赞 -->
				<div class="footer-item" @click="onPraise" v-show="!isPraise">
					<div class="picture"><img src="@/assets/img/wz1.png" /></div>
				</div>
				<div class="footer-item footer-item-like" @click="unPraise" v-show="isPraise">
					<div class="picture"><img src="@/assets/img/wz11.png" /></div>
				</div>
				<!-- 收藏 -->
				<div class="footer-item" @click="onCollect" v-show="!isCollect">
					<div class="picture"><img src="@/assets/img/wz2.png" /></div>
				</div>
				<div class="footer-item footer-item-collect" @click="unCollect" v-show="isCollect">
					<div class="picture"><img src="@/assets/img/wz22.png" /></div>
				</div>
				<!-- 分享 -->
				<div class="footer-item footer-item-share" @click="onshare()">
					<div class="picture"><img src="@/assets/img/wz33.png" /></div>
				</div>
				<Share :loading="isShowShare" @Touch="share()"></Share>
			</div>
		</div>
	</div>
</template>
<script>
	import News from '@/api/news';
	import Vant from '@/vendor/vant';
	import Share from '@/components/Share';
	import LoadingPage from '@/components/LoadingPage';
	export default {
		name: 'NewsDetail',
		data() {
			return {
				loadingPage: true,
				isPraise: '',
				isCollect: '',
				newsDetail: '',
				pageId: '',
				typeId: 1,
				isShowShare: false,
			};
		},
		created() {
			this.pageId = this.$route.params.id;
			this.onDetail();

			let params = {
				page_id: this.pageId,
				type_id: this.typeId
			};
			News.articleRead({
					article_id: this.pageId
				})
				.then(result => {
					Vant.Toast.success('阅读成功，爱心值+1个');
				})
				.catch(error => {
					Vant.Toast('已阅读');
				});

			News.isCollect(params)
				.then(result => {
					this.isCollect = result.data.is_collect;
				})
				.catch(error => {});

			News.isPraise(params)
				.then(result => {
					this.isPraise = result.data.is_praise;
				})
				.catch(error => {});

		},
		methods: {
			// 分享
			onshare() {
				this.isShowShare = true;
			},
			share() {
				this.isShowShare = false;
			},
			onDetail() {
				News.articleDetail({
						article_id: this.pageId
					})
					.then(result => {
						if (result.data.type == 2) {
							window.location.replace(result.data.redirect);
						} else {
							this.newsDetail = result.data;
							this.loadingPage = false;
							
							wx.onMenuShareTimeline({
								title: this.newsDetail.title,
								link: window.Location.href,
								imgUrl: 'https://srsyjweb.jxwwkj.cn/logo.jpg',
								success: () => {
									this.isShowShare = false;
									News.articleShare({
											article_id: this.pageId
										})
										.then(result => {
											Vant.Toast.success('分享成功');
										})
										.catch(error => {
											Vant.Toast('已分享');
										});
								},
								error: () => {
									console.log(12);
								}
							});
							wx.onMenuShareAppMessage({
								title: this.newsDetail.title,
								link: window.Location.href,
								imgUrl: 'https://srsyjweb.jxwwkj.cn/logo.jpg',
								success: () => {
									this.isShowShare = false;
									News.articleShare({
											article_id: this.pageId
										})
										.then(result => {
											Vant.Toast.success('分享成功，爱心值+5个');
										})
										.catch(error => {
											Vant.Toast('已分享');
										});
								},
								error: () => {
									console.log(12);
								}
							});
						}
					})
					.catch(error => {
						console.log(error);
					});
			},
			// 点赞
			onPraise() {
				let params = {
					page_id: this.pageId,
					type_id: this.typeId
				};
				News.praise(params)
					.then(result => {
						this.isPraise = true;
						Vant.Toast.success('点赞成功');
						this.newsDetail.approval++;
					})
					.catch(error => {
						Vant.Toast(error.msg);
					});
			},
			// 取消点赞
			unPraise() {
				let params = {
					page_id: this.pageId,
					type_id: this.typeId
				};
				News.unPraise(params)
					.then(result => {
						this.isPraise = false;
						Vant.Toast('取消点赞成功');
						this.newsDetail.approval--;
					})
					.catch(error => {
						Vant.Toast('取消点赞失败');
					});
			},
			// 收藏
			onCollect() {
				let params = {
					page_id: this.pageId,
					type_id: this.typeId
				};
				News.collect(params)
					.then(result => {
						this.isCollect = true;
						Vant.Toast.success('收藏成功');
					})
					.catch(error => {
						Vant.Toast('收藏失败');
					});
			},
			// 取消收藏
			unCollect() {
				let params = {
					page_id: this.pageId,
					type_id: this.typeId
				};
				News.unCollect(params)
					.then(result => {
						this.isCollect = false;
						Vant.Toast('取消收藏成功');
					})
					.catch(error => {
						Vant.Toast('取消收藏失败');
					});
			}
		},
		components: {
			Share,
			LoadingPage
		}
	};
</script>

<style scoped lang="less">
	.news-detail {
		width: 100%;
		min-height: 100vh;
		background-color: #fff;

		.container {
			padding-top: 20px;
			box-sizing: border-box;

			.head-nav {
				margin: 20px;
				margin-top: 0;
				border-bottom: 1px solid #ddd;
				padding-bottom: 2px;

				.head-title {
					font-size: 18px;
					line-height: 26px;
					color: #222;
					margin-bottom: 5px;
					font-weight: bold;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}

				.head-bottom {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.head-bottom-item {
						display: flex;
						justify-content: space-between;
						align-items: center;

						.text {
							font-size: 12px;
							color: #777;
							margin-right: 5px;
							
							&.title{
								width: 90px;
								max-width: 90px;
							}
						}

						.text:last-child {
							margin-right: 0;
						}
					}
				}
			}

			.content {
				margin: 0 20px;
				border-bottom: 1px solid #ddd;

				.content-top {
					padding-bottom: 15px;
					font-size: 16px;
					line-height: 26px;
					color: #222;
				}

				.content-bottom {
					padding-bottom: 15px;
					font-size: 16px;
					color: #222;
					overflow: hidden;
					box-sizing: border-box;
					display: flex;
					align-items: center;

					a {
						display: inline-block;
						width: 260px !important;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.btn {
						background-color: #4399ff;
						color: #fff;
						border-radius: 6px;
						text-align: center;
						padding: 0 6px;
						margin-left: 10px;
						min-width: 40px;
					}
				}
			}

			.publ {
				width: 60px;
				height: 60px;
				border-radius: 50%;
				color: #aaa;
				padding: 18px;
				box-sizing: border-box;
			}

			.footer {
				padding: 20px 80px 50px 80px;
				display: flex;
				justify-content: space-between;

				.footer-item {
					background-color: #ddd;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					color: #aaa;
					padding: 18px;
					box-sizing: border-box;

					.picture {
						width: 24px;
						height: 24px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.footer-item-like {
					background: #ff6288;
					.publ();
				}

				.footer-item-collect {
					background: #ffb609;
					.publ();
				}

				.footer-item-share {
					background: #4399ff;
					.publ();
				}
			}
		}
	}
</style>
